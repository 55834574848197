<template>
  <div>
    <!-- 下单人信息 -->
    <en-table-layout :tableData="tableData.data" :loading="loading" :tips="true">
      <!-- 交易状态 -->
      <div slot="tips" style="display:flex;justify-content:flex-end;width:100%;">
        <h3 style="width:100%;text-align:center;color:#333;" v-if="tableData.data.length !== 0">
          交易状态：{{ tableData.data[0].order_status === "CONFIRM" ? "待付款" : tableData.data[0].order_status === "PAID_OFF" ?
      "待参加" : tableData.data[0].order_status === "IN" ? "进行中" : tableData.data[0].order_status === "COMPLETE" ?
        "已完成" : tableData.data[0].order_status === "REFUNDING" ? "待退款" : tableData.data[0].order_status === "REFUNDED"
          ? "已退款" : tableData.data[0].order_status === "CANCELLED" ? "已取消" : "" }}
        </h3>
      </div>
      <!-- 表格上部的信息 -->
      <div slot="toolbar">
        <h4>下单人信息</h4>
      </div>
      <template slot="table-columns">
        <!-- 下单人用户名 --->
        <el-table-column prop="member_name" label="下单人用户名" />
        <!-- 下单人手机号 --->
        <el-table-column prop="phone" label="下单人手机号" />
        <!-- 微信号 --->
        <!-- <el-table-column prop="pay_nickname" label="微信号" /> -->
      </template>
    </en-table-layout>

    <!-- 票种明细 -->
    <en-table-layout :tableData="tableData.data" :loading="loading" :tips="true">
      <!-- 表格上部的信息 -->
      <div slot="toolbar">
        <h4>票种明细</h4>
      </div>
      <template slot="table-columns">
        <!-- 期次类型 -->
        <el-table-column label="期次类型">
          <template slot-scope="scope">
            {{ scope.row.ticket_num === 0 ? '不多期' : '多期' }}
          </template>
        </el-table-column>
        <!-- 活动期次 --->
        <el-table-column label="活动期次">
          <template slot-scope="scope">
            <div>{{ scope.row.ticket_num === 0 ? "单期" : "第" + scope.row.ticket_num + "期" }}
              {{ MixinUnixToDate("", "", scope.row.start_date).split("
              ")[0]}}至{{ MixinUnixToDate("", "", scope.row.end_date).split(" ")[0] }}</div>
          </template>
        </el-table-column>
        <!-- 活动场次 --->
        <el-table-column label="活动场次">
          <template slot-scope="scope">
            <div>{{ Number(scope.row.time_start.split(":")[0]) >= 12 ? "下午场" : "上午场" }}
              {{ scope.row.time_start }}-{{ scope.row.time_end }}</div>
          </template>
        </el-table-column>
        <!-- 票种规格 --->
        <el-table-column prop="ticket_name" label="票种规格" />
        <!-- 数量 --->
        <el-table-column prop="num" label="数量" />
        <!-- 票种单价 --->
        <el-table-column label="票种单价">
          <template slot-scope="scope">
            <div style="color:#FE5558;">{{ scope.row.price }}</div>
          </template>
        </el-table-column>
        <!-- 电子券码 --->
        <el-table-column prop="eticket_no" label="电子券码" />
        <!-- 核销状态 -->
        <el-table-column label="核销状态">
          <template slot-scope="scope">
            <!-- 0 未核销  1已核销  2已过期 -->
            <div v-if="scope.row.verify_status === '1'">未核销</div>
            <div v-if="scope.row.verify_status === '2'">已核销</div>
            <div v-if="scope.row.verify_status === '3'">已过期</div>
          </template>
        </el-table-column>
      </template>
    </en-table-layout>

    <!-- 出行人信息 -->
    <en-table-layout :tableData="travelersTableData.data" :loading="loading" :tips="true">
      <!-- 表格上部的信息 -->
      <div slot="toolbar">
        <h4>出行人信息</h4>
      </div>
      <template slot="table-columns">
        <!-- 姓名 --->
        <el-table-column prop="chinese_name" label="出行人姓名" />
        <!-- 英文姓 --->
        <!-- <el-table-column prop="eng_first_name" label="英文姓" /> -->
        <!-- 英文名 --->
        <!-- <el-table-column prop="eng_second_name" label="英文名" /> -->
        <!-- 手机号 --->
        <el-table-column prop="phone" label="出行人手机号" />
        <!-- 证件类型 --->
        <!-- <el-table-column label="证件类型">
          <template slot-scope="scope">
            <div>{{ scope.row.doc_type === 1 ? "身份证" : scope.row.doc_type === 2 ? "护照" : scope.row.doc_type === 3 ? "港澳台通行证" : "其他" }}</div>
          </template>
  </el-table-column> -->
        <!-- 证件号 --->
        <!-- <el-table-column prop="doc_nums" label="证件号" /> -->
        <!-- 证件有效期截止日 --->
        <!-- <el-table-column prop="doc_validity" label="证件有效期截止日">
          <template slot-scope="scope">
            <div>{{ scope.row.doc_validity ? scope.row.doc_validity : "终身有效" }}</div>
          </template>
        </el-table-column> -->
        <!-- 国籍 --->
        <!-- <el-table-column label="国籍">
          <template slot-scope="scope">
            <div>{{ scope.row.nationality === 1 ? "中国" : "其他" }}</div>
          </template>
        </el-table-column> -->
        <!-- 身份类型 -->
        <!-- <el-table-column label="身份类型">
          <template slot-scope="scope">
            {{ scope.row.identity_type === 1 ? '成人' : '儿童' }}
          </template>
        </el-table-column> -->
        <!-- 儿童年龄 -->
        <!-- <el-table-column label="儿童年龄">
          <template slot-scope="scope">
            {{ scope.row.identity_type === 0 ? scope.row.child_age : '' }}
          </template>
        </el-table-column> -->
        <!-- 是否本人 -->
        <!-- <el-table-column label="是否本人">
          <template slot-scope="scope">
            {{ scope.row.self === 0 ? '否' : '是' }}
          </template>
        </el-table-column> -->
      </template>
    </en-table-layout>

    <!-- 紧急联系人 -->
    <en-table-layout :tableData="urgentTableData.data" :loading="loading" :tips="true">
      <!-- 表格上部的信息 -->
      <div slot="toolbar">
        <h4>紧急联系人</h4>
      </div>
      <template slot="table-columns">
        <!-- 紧急联系人 --->
        <el-table-column prop="name" label="紧急联系人" />
        <!-- 手机号 --->
        <el-table-column prop="phone" label="手机号" />
        <!-- 与本人关系 --->
        <!-- <el-table-column prop="relation_ship" label="与本人关系" /> -->
      </template>
    </en-table-layout>

    <!-- 订单详情 -->
    <en-table-layout :tableData="tableData.data" :loading="loading" :tips="true">
      <!-- 表格上部的信息 -->
      <div slot="toolbar">
        <h4>订单详情</h4>
      </div>
      <template slot="table-columns">
        <!-- 订单编号 -->
        <el-table-column prop="trade_sn" label="订单编号" />
        <!-- 下单时间 --->
        <el-table-column prop="create_date" :formatter="MixinUnixToDate" label="下单时间" />
        <!-- 支付时间 --->
        <el-table-column prop="payment_time" :formatter="MixinUnixToDate" label="支付时间" />
        <!-- 支付方式 --->
        <el-table-column prop="payment_method_name" label="支付方式" />
        <!-- 支持退款 --->
        <el-table-column label="是否支持退款">
          <template slot-scope="scope">
            <div>{{ scope.row.refund_type === 0 ? "不支持退款" : "支持退款" }}</div>
          </template>
        </el-table-column>
        <!-- 订单金额 --->
        <el-table-column prop="cost" label="订单金额" />
        <!-- 优惠券 --->
        <el-table-column label="优惠券">
          <template slot-scope="scope">
            <div>{{ scope.row.cost - scope.row.order_price }}</div>
          </template>
        </el-table-column>
        <!-- 实付金额 --->
        <el-table-column label="实付金额">
          <template slot-scope="scope">
            <div style="color:#FE5558;">{{ scope.row.order_price }}</div>
          </template>
        </el-table-column>
      </template>
    </en-table-layout>

    <!-- 活动详情 -->
    <en-table-layout :tableData="tableData.data" :loading="loading" :tips="true">
      <!-- 表格上部的信息 -->
      <div slot="toolbar">
        <h4>活动详情</h4>
      </div>
      <template slot="table-columns">
        <!--  活动名称 -->
        <el-table-column label="活动名称" width="225">
          <template slot-scope="scope">
            <div class="imgBox">
              <img :src="scope.row.original" />
              <div :title="scope.row.name">{{ scope.row.name }}</div>
            </div>
          </template>
        </el-table-column>
        <!--  活动编号 -->
        <el-table-column prop="sku_sn" label="活动编号" />
        <!--  活动一级分类 -->
        <el-table-column prop="cat_first_name" label="活动一级分类" />
        <!--  活动二级分类 -->
        <el-table-column prop="cat_second_name" label="活动二级分类" />
        <!--  原价 -->
        <el-table-column prop="mktprice" label="原价" />
        <!--  销售价 -->
        <el-table-column prop="price" label="销售价" />
        <!--  累计销量 -->
        <el-table-column prop="buy_count" label="累计销量" />
        <!--  名额限制 -->
        <el-table-column prop="sale_limit_num" label="名额限制" />
        <!--  剩余名额 -->
        <el-table-column prop="quantity" label="剩余名额" />
        <!--  活动开始时间 -->
        <el-table-column prop="start_date" :formatter="MixinUnixToDate" label="活动开始时间" />
        <!--  活动结束时间 -->
        <el-table-column prop="end_date" :formatter="MixinUnixToDate" label="活动结束时间" />
        <!--  活动下架时间 -->
        <el-table-column prop="off_time" :formatter="MixinUnixToDate" label="活动下架时间" />
      </template>
    </en-table-layout>
    <!-- 退款信息 -->
    <en-table-layout :tableData="refundTableData" v-show="true" :loading="loading" :tips="true">
      <!-- 表格上部的信息 -->
      <div slot="toolbar">
        <h4>退款信息</h4>
      </div>
      <template slot="table-columns">
        <!-- 退款类型 -->
        <el-table-column label="退款类型">
          <template slot-scope="scope">
            {{ scope.row.launch_status === "1" ? '用户' : '商户' }}
          </template>
        </el-table-column>
        <!--  申请退款时间 -->
        <el-table-column prop="create_time" :formatter="MixinUnixToDate" label="申请退款时间" />
        <!--  退款原因 -->
        <el-table-column prop="reason" label="退款原因" />
        <!--  问题描述 -->
        <el-table-column prop="problem_desc" label="问题描述" />
        <!--  相关图片 -->
        <el-table-column label="相关图片" width="230">
          <template slot-scope="scope">
            <div class="imgBox" style="flex-wrap:wrap;width:230px;">
              <img v-for="(item, index) in scope.row.images" :key="index" :src="item.img" style="margin-bottom:10px;"
                alt="" @click="clickImg(item)" />
            </div>
          </template>
        </el-table-column>
        <!-- 退款比例 -->
        <el-table-column prop="point" label="退款比例" />
        <!--  应退金额 -->
        <el-table-column label="应退金额">
          <template slot-scope="scope">
            <div style="color:#FE5558;">{{ scope.row.refund_price }}</div>
          </template>
        </el-table-column>
        <!-- 实退金额 -->
        <el-table-column prop="actual_price" label="实退金额" />
      </template>
    </en-table-layout>
    <!-- 放大图片 -->
    <big-img v-if="showImg" @clickit="viewImg" :imgSrc="imgSrc"></big-img>
  </div>
</template>

<script>
import * as API_orderList from "@/api/orderList";
import BigImage from '@/components-new/big-Image/big-image'

export default {
  name: "activityList",
  components: {
    'big-img': BigImage
  },
  data () {
    return {
      //  列表loading状态
      loading: false,

      //  所有列表数据
      tableData: {
        data: [],
      },

      //  出行人数据
      travelersTableData: {
        data: [],
      },

      //  紧急联系人信息
      urgentTableData: {
        data: [],
      },

      //  退款信息
      refundTableData: [],

      showImg: false,
      imgSrc: ''
    };
  },
  mounted () {
    const order_id = this.$route.params.order_id;
    this.GET_tradeOrdersOne(order_id);
  },
  activated () {
    const order_id = this.$route.params.order_id;
    this.GET_tradeOrdersOne(order_id);
  },
  methods: {
    /** 放大图片 */
    clickImg (item) {
      this.showImg = true;
      // 获取当前图片地址
      this.imgSrc = item.img
    },
    viewImg () {
      this.showImg = false;
    },
    /** 获取售后服务详细信息 */
    GET_NewrefundDetail (service_sn) {
      API_orderList.NewrefundDetail(service_sn).then((res) => {
        console.log('refundTableData', res);
        this.refundTableData = [res];
      });
    },
    /** 获取订单列表 */
    GET_tradeOrdersOne (order_id) {
      API_orderList.tradeOrdersOne(order_id).then((res) => {
        this.tableData.data.push(res);
        this.travelersTableData.data = res.travelers;
        this.urgentTableData.data = res.emergenciers;
        // console.log(res);
        if (
          res.order_status === "REFUNDING" ||
          res.order_status === "REFUNDED"
        ) {
          this.GET_NewrefundDetail(res.trade_sn);
        }
      });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.imgBox {
  display: flex;
  align-items: center;

  img {
    width: 64px;
    height: 48px;
    margin-right: 10px;
  }

  div {
    width: 150px;
    font-size: 16px;
    line-height: 24px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
}

.buttonBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

::v-deep .el-table td:not(.is-left) {
  text-align: center;
}

::v-deep .toolbar {
  padding: 0 36px !important;

  h4 {
    color: #333;
  }
}

::v-deep .tips-info {
  padding: 0 !important;
}
</style>
